<template>
    <div class="heading" >
        <div class="heading_title">
            <Dropdown v-model="selectedDepartment" :options="departments" optionLabel="Name" optionValue="_id" placeholder="Select a department" @change="changeDepartment" />
        </div>
    </div>
    <div class="plans_page">
        <div class="plans_page__content">
            <div class="plans_page__plan_container">
                <div class="plans_features" >
                    <template v-for="featureData in featureDataList">
                        <FeaturesTable :featureData="featureData" :departmentId="featureData.departmentId" :priceId="featureData.priceId" :productId="featureData.productId" />
                    </template>
                    <template v-if="showSpinner">
                        <div class="progressSpinnerContainer" style="" @showSpinnerEmit="showSpinnerEmit($event)" >
                            <ProgressSpinner />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import FeaturesTable from "@/components/plans/featuresTable"
import Dropdown from 'primevue/dropdown';
import http from '@/mixins/api';
import ProgressSpinner from 'primevue/progressspinner';

export default {
    name: 'Info',
    components: {
        FeaturesTable,
        Dropdown,
        ProgressSpinner,
    },
    async beforeRouteEnter(route, redirect, next) {
        const query = route.query
        const departmentId = route.params.departmentId
        next(vm => vm.setData({departmentId: departmentId}))
    },

    async beforeRouteUpdate(route, redirect, next) {
        const query = route.query
        const departmentId = route.params.departmentId
        this.setData({departmentId: departmentId})
        next()
    },
    data: function() {
        return {
            selectedDepartment: null,
            departments: [],
            Department: {},
            featureDataList: [],
            showSpinner: false,
        }
    },

    methods: {

        changeDepartment: function(e) {
            this.$router.push({ path: `/plans/info/${e.value}`});
        },

        getData: function() {

        },

        setData: async function(data) {
            this.showSpinner = true
            const DepartmentId = data.departmentId
            res
            const res = await http.post('/plan/ViewProducts', {DepartmentId: DepartmentId});
            if(res.Success) {
                this.featureDataList = []
                const paidCustomer = res.paidCustomer || {}
                const allProducts = res.allProducts
                const allPrices = res.allPrices
                const DepartmentList = this.$store.getters["userBranchInfo/getBelongDepartmentList"]
                const department = DepartmentList.find(x => x._id == DepartmentId)
                console.log(res)
                const featureDataList_tmp = []
                for(let i = 0; i < allProducts.length; i++) {
                    const allProduct = allProducts[i]
                    const prices = allPrices.filter(x => x.product == allProduct.id)
                    console.log(prices)
                    if(prices) {
                        const yearPlan = prices.find(x => x.metadata.type == "year")
                        const monthPlan = prices.find(x => x.metadata.type != "year")
                        const productId = allProduct.id
                        const priceId = prices.id
                        const yearPriceId = yearPlan.id
                        const monthPriceId = monthPlan.id
                        const name = allProduct.name

                        featureDataList_tmp.unshift({
                            title: this.$i18n.tc("plans." + name + ".title"),
                            subtitle: this.$i18n.tc("plans." + name + ".forUser"),
                            base_price: 0,
                            // price: monthPlan.unit_amount ? monthPlan.unit_amount_decimal.replace(/([1-9].*)([0-9]{2})/, "$1.$2") : null,
                            price: monthPlan.unit_amount ? monthPlan.unit_amount_decimal.replace(/([1-9].*)([0-9]{2})/, "$1") : null,
                            base_price_year: 0,
                            // price_year: yearPlan.unit_amount ? yearPlan.unit_amount_decimal.replace(/([1-9].*)([0-9]{2})/, "$1.$2") : null,
                            price_year: yearPlan.unit_amount ? yearPlan.unit_amount_decimal.replace(/([1-9].*)([0-9]{2})/, "$1") : null,
                            isPaid: paidCustomer.ProductId  == allProduct.id,
                            priceinfo: "priceinfo",
                            funcInfo: this.$i18n.tm("plans." + name + ".funcInfo"),
                            funcList: this.$i18n.tm("plans." + name + ".funcList"),
                            departmentId: DepartmentId,
                            productId: productId,
                            yearPriceId: yearPriceId,
                            monthPriceId: monthPriceId,
                            planName: name,
                            upgradeQuery: {
                                showStripe: true,
                            }
                        })
                    }
                }

                this.departments = DepartmentList
                this.selectedDepartment = DepartmentId
                this.Department = department

                featureDataList_tmp.unshift({
                    title: this.$i18n.tc("plans.free.title"),
                    subtitle: this.$i18n.tc("plans.free.forUser"),
                    base_price: 0,
                    price: "0",
                    base_price_year: 0,
                    price_year: "0",
                    isPaid: paidCustomer.ProductId == undefined,
                    priceinfo: "priceinfo",
                    // func: this.$i18n.tc("plans.mainFunc"),
                    funcInfo: this.$i18n.tc("plans.free.funcInfo"),
                    funcList: this.$i18n.tm("plans.free.funcList"),
                    departmentId: DepartmentId,
                    planName: "free",
                    upgradeQuery: {
                        play: "cancel",
                        showStripe: false,
                    }
                })
                featureDataList_tmp.push({
                    title: this.$i18n.tc("plans.enterprise.title"),
                    subtitle: this.$i18n.tc("plans.enterprise.forUser"),
                    // func: this.$i18n.tc("plans.mainFunc"),
                    priceinfo: "priceinfo",
                    funcInfo: this.$i18n.tc("plans.enterprise.funcInfo"),
                    // funcList: this.$i18n.tc("plans.enterprise.funcList").map(x => {return {text: x}})
                    // funcList: this.$i18n.tc("plans.enterprise.funcList"),
                    funcList: this.$i18n.tm("plans.enterprise.funcList"),
                    buttonText: this.$i18n.tm("plans.inquiryButtonText") ,
                    upgradeURL: this.$i18n.tm("plans.inquiryButtonURL"),
                    planName: "enterprise",

                })
                this.featureDataList = featureDataList_tmp
            }
            this.showSpinner = false
        },
    }
}
</script>

<style scoped>
</style>
