<template>
    <div class="plans_features__table">
        <div class="plans_features__table__title_container">
            <div class="plans_features__table__title_container__text">
                {{featureData.title}}
            </div>
        </div>
        <div class="plans_features__table__price_container">
            <div class="plans_features__table__price_container__text" style="">
                <template v-if="featureData.price">
                        
                    <span style="font-size: 40px; color: #0078d4;">${{ featureData.price }} </span>
                    <span class="plans_features__table__price_container__text_sub"> {{ $t("plans.priceUnit")}} </span>
                    <div class="" style="display: block;  margin-left: 20px;color: rgb(80, 95, 121); color: #000; font-size: 12px; line-height: 1.46668; font-weight: 200;padding-left: 20px; padding-right: 20px;" >
                        <span>{{featureData.subtitle}}</span><br />
                        <span>({{ featureData.price_year ? (featureData.price_year + $t("plans.priceYearUnit")) : " "}})</span>
                    </div>
                </template>
                <template v-else>
                    <div style="font-size: 40px; height: 55px;"> </div>
                    <div class="" style="display: block;  margin-left: 20px;color: rgb(80, 95, 121); color: #000; font-size: 12px; line-height: 1.46668; font-weight: 200;padding-left: 20px; padding-right: 20px;" >
                        <span>{{featureData.subtitle}}</span><br />
                        <span style="">{{$t("plans.inquiryText")}} </span>
                    </div>
                </template>
            </div>

        </div>
        <div class="plans_features__table__upgrade_container">
            <Button class="p-button-primary" style="background: #0078d4;"  @click="upgrade" v-if="featureData.isPaid" disabled> {{$t("plans.currentPlanButtonText")}} </Button>
            <Button class="p-button-primary" style="background: #0078d4;"  @click="upgrade" v-else > {{ featureData.buttonText ? featureData.buttonText : $t("plans.changePlanButtonText")}} </Button>
        </div>
        <div class="plans_features__table__func_container">
            <div class="plans_features__table__func_container__text">
                {{featureData.func}}
            </div>
            <div class="plans_features__table__func_container__text_children">
                <span style="font-size: 20px; font-weight: 500; ">{{featureData.funcInfo}}</span>
                <ul style="margin-top: 20px;">
                    <template v-for="funcText in featureData.funcList">
                        <li style="font-size: 15px;">
                            <img src="@/assets/check.png" style="height: 16px;" />
                            <span>{{$rt(funcText)}}</span>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>


<script>

import Button from 'primevue/button';
import http from '@/mixins/api';

export default {
    name: 'features',
    props: ["featureData", "departmentId", "productId"],
    components: {
        Button,
    },
    data: function() {
        return {
        }
    },


    created() {
    },
    computed: {
    },
    methods: {
        async upgrade(e) {


            const productId = this.productId ? this.productId : "none"
            const upgradeQuery = this.featureData.upgradeQuery
            const upgradeURL = this.featureData.upgradeURL
            if(upgradeURL) {
                window.open(upgradeURL, '_blank');
            } else {
                this.$router.push({ path: `/plans/upgrade/${this.departmentId}/${productId}`, query: {planName: this.featureData.planName, ...upgradeQuery} });
            }
            // let resolvedRoute = this.$router.resolve({
            //     path: `/plans/upgrade/${this.departmentId}/${productId}`,
            //     query: {planName: this.featureData.planName, ...upgradeQuery} 
            // });
            // window.open(resolvedRoute.href, '_blank');

        }
    },
}
</script>

<style scoped>
.plans_features__table__func_container__text_children {
    border-top: 1px solid rgb(223, 225, 230);
    height: auto;
    padding-top: 1.25rem;
}
.plans_features__table__func_container__text_children ul {
    font-size: 1rem;
    list-style-type: none;
    margin: 0px;
    padding: 0px 0px 0px 1.5rem;
}
.plans_features__table__func_container__text_children li {
    text-align: left;
    margin: 0px 0px 0.5rem;
    position: relative;
}
</style>
